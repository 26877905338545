.modal-clean {
	.modal-content {
		box-shadow: 0 0 16px rgba(0,0,0,0.15);
		border: none;
		border-radius: 6px;


		.title-image {
			max-width: 70%;
			max-height: 56px;
			margin-top: 30px;
			margin-bottom: 30px;
		}

		p.strong {
			font-weight: 700;
			color: #777777;
			font-size: 16px;
		}

		.m-t-lg { margin-top: 45px; }
		.btn {
			margin-top: 5px;
			&.btn-success {
				text-transform: uppercase;
				border-radius: 40px;
				font-weight: 600;
				letter-spacing: 1px;
				background: $brand-secondary;
				border-color: $brand-secondary;
				width: 180px;
				padding: 10px 12px;
				font-size: 16px;
			}
		}
		.powered-by {
			text-align: right;

			p { margin-bottom: 0; }
			img {
				max-width: 100%;
				height: 30px;
			}
		}
		@media(min-width: 768px) {
			.modal-body {
				padding: 40px;
			}
		}
	}
}