@import "vars-is";

/*
* Custom
*/

#login-background {
  background-color:#007999;
}

[v-cloak] {
  display: none;
}

.hide-nav {
  margin-left:0px!important;
}

.bounce-out-leave {
  animation: bounceOutRight .75s;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes bounce-out {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}

.bounceOutRight-leave {
  animation: fadeOut .75s;
}

.bounceOutRight-enter {
  animation: fadeIn .75s;
}

.fade-transition {
  transition: all 5s ease;
}

.fade-enter {
  opacity: 0;
}

.fade-leave {
  opacity: 1;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0);
  }

  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }

  to {
    -webkit-transform: none;
    transform: none;
  }
}

.bounceInLeft-enter {
  -webkit-animation-name: bounceInLeft;
  animation: bounceInLeft;
}


.widget-link {
  cursor: pointer;
}

.fa-percent:before {
  content: "%";
  font-family: "Open Sans";
  font-weight: 900;
}

/**
* Select 2 syles
*/
.select2 {
  width: 100% !important;
}

.select2-container .select2-selection--single {
  height: 46px;
  border-radius: 6px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 46px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b  {
  margin-top: 3px;
}

/**
* Full block table
*/
.block-table {
  margin-bottom: 0px;
  margin-left: -20px;
  margin-right: -20px;
  max-width: none;
  width:auto;

}

/*-- Chart --*/
.c3 svg {
  //font: 10px sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.c3 path, .c3 line {
  fill: none;
  //stroke: #000;
  }

.c3 text {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  color: #777;
  }

.c3-legend-item-tile, .c3-xgrid-focus, .c3-ygrid, .c3-event-rect, .c3-bars path {
  shape-rendering: crispEdges; }

.c3-chart-arc path {
  stroke: #fff; }

.c3-chart-arc text {
  //fill: #fff;
  font-size: 13px; }

/*-- Axis --*/
/*-- Grid --*/
.c3-grid line {
  stroke: #aaa; }

.c3-grid text {
  fill: #aaa; }

.c3-xgrid, .c3-ygrid {
  stroke-dasharray: 3 3; }

/*-- Text on Chart --*/
.c3-text.c3-empty {
  fill: #808080;
  font-size: 2em; }

/*-- Line --*/
.c3-line {
  stroke-width: 3px; }

/*-- Point --*/
.c3-circle._expanded_ {
  stroke-width: 1px;
  stroke: white; }

.c3-selected-circle {
  fill: white;
  stroke-width: 2px; }

/*-- Bar --*/
.c3-bar {
  stroke-width: 0; }

.c3-bar._expanded_ {
  fill-opacity: 0.75; }

/*-- Focus --*/
.c3-target.c3-focused {
  opacity: 1; }

.c3-target.c3-focused path.c3-line, .c3-target.c3-focused path.c3-step {
  stroke-width: 2px; }

.c3-target.c3-defocused {
  opacity: 0.3 !important; }

/*-- Region --*/
.c3-region {
  fill: steelblue;
  fill-opacity: 0.1; }

/*-- Brush --*/
.c3-brush .extent {
  fill-opacity: 0.1; }

/*-- Select - Drag --*/
/*-- Legend --*/
.c3-legend-item {
  font-size: 12px; }

.c3-legend-item-hidden {
  opacity: 0.15; }

.c3-legend-background {
  opacity: 0.75;
  fill: white;
  stroke: lightgray;
  stroke-width: 1; }

/*-- Title --*/
.c3-title {
  font: 14px sans-serif; }

/*-- Tooltip --*/
.c3-tooltip-container {
  z-index: 10; }

.c3-tooltip {
  border-collapse: collapse;
  border-spacing: 0;
  background-color: #fff;
  empty-cells: show;
  -webkit-box-shadow: 7px 7px 12px -9px #777777;
  -moz-box-shadow: 7px 7px 12px -9px #777777;
  box-shadow: 7px 7px 12px -9px #777777;
  opacity: 0.9; }

.c3-tooltip tr {
  border: 1px solid #CCC; }

.c3-tooltip th {
  background-color: #394263;
  font-size: 14px;
  padding: 2px 5px;
  text-align: left;
  color: #FFF; }

.c3-tooltip td {
  font-size: 13px;
  padding: 3px 6px;
  background-color: #fff;
  border-left: 1px dotted #999; }

.c3-tooltip td > span {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 6px; }

.c3-tooltip td.value {
  text-align: right; }

/*-- Area --*/
.c3-area {
  stroke-width: 0;
  opacity: 0.2; }

/*-- Arc --*/
.c3-chart-arcs-title {
  dominant-baseline: middle;
  font-size: 1.3em; }

.c3-chart-arcs .c3-chart-arcs-background {
  fill: #e0e0e0;
  stroke: none; }

.c3-chart-arcs .c3-chart-arcs-gauge-unit {
  fill: #000;
  font-size: 16px; }

.c3-chart-arcs .c3-chart-arcs-gauge-max {
  fill: #777; }

.c3-chart-arcs .c3-chart-arcs-gauge-min {
  fill: #777; }

.c3-chart-arc .c3-gauge-value {
  fill: #000;
  /*  font-size: 28px !important;*/ }

@import "sweetalert2";
@import "./invest-smart/quick-search";
@import "./invest-smart/panel-alert";
@import "./invest-smart/modals";
@import "./invest-smart/incoming-investment";
@import "./invest-smart/style-is";
@import "document-scanner";
