html, body {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  min-height: 100%;
}

.panel-document-scanner {
  border: none;
  border-radius: 0.5rem;
  background: #5bc0de;

  h2 {
    font-size: 18px;
    margin-top: 0;
    font-weight: 600;
    color: darken(#5bc0de, 40%);
  }
  p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: darken(#5bc0de, 40%);
    border-color: darken(#5bc0de, 40%);
  }
  .btn {
    margin-top: 0;
    border-radius: 0.375rem;
  }
  svg {
    display: none !important;
    height: 60px;
    color: darken(#5bc0de, 24%);
    margin-left: 8px;
    margin-right: 8px;
  }

  .container-left {
    flex-basis: 0;
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 32px;
    display: flex;
    align-items: center;
  }
  .container-btn,
  .graphic {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
    display: flex;
    align-items: center;
  }
  @media(min-width: 576px) {
    .container-left {
      flex-basis: 0;
      flex-grow: 1;
    }
    svg {
      display: block !important;
      margin-left: 16px;
      margin-right: 16px;
    }
  }
}

.d-flex {
  display: flex;
  flex-flow: wrap
}

.modal-mobile-scanner-link {
  .radio-inline {
    padding-left: 0;
    margin-top: 0;
  }
  .form-control {
    height: 48px;
    font-size: 18px;
  }

  .link-sent {
    margin-top: 32px;
    color: #a5dc86;
    text-align: center;
    font-size: 28px;
  }
}


//
// Animated Tick Icon
// --------------------------------------------------
.swal-icon{width:80px;height:80px;border-width:4px;border-style:solid;border-radius:50%;padding:0;position:relative;box-sizing:content-box;margin:20px auto}
// .swal-icon:first-child{margin-top:32px}
.swal-icon--success{ border-color:#a5dc86 }
.swal-icon--success:after,
.swal-icon--success:before{content:"";border-radius:50%;position:absolute;width:60px;height:120px;background:#fff;-webkit-transform:rotate(45deg);transform:rotate(45deg)}
.swal-icon--success:before{border-radius:120px 0 0 120px;top:-7px;left:-33px;-webkit-transform:rotate(-45deg);transform:rotate(-45deg);-webkit-transform-origin:60px 60px;transform-origin:60px 60px}
.swal-icon--success:after{border-radius:0 120px 120px 0;top:-11px;left:30px;-webkit-transform:rotate(-45deg);transform:rotate(-45deg);-webkit-transform-origin:0 60px;transform-origin:0 60px;-webkit-animation:rotatePlaceholder 4.25s ease-in;animation:rotatePlaceholder 4.25s ease-in}
.swal-icon--success__ring{width:80px;height:80px;border:4px solid hsla(98,55%,69%,.2);border-radius:50%;box-sizing:content-box;position:absolute;left:-4px;top:-4px;z-index:2}
.swal-icon--success__hide-corners{width:5px;height:90px;background-color:#fff;padding:1px;position:absolute;left:28px;top:8px;z-index:1;-webkit-transform:rotate(-45deg);transform:rotate(-45deg)}
.swal-icon--success__line{height:5px;background-color:#a5dc86;display:block;border-radius:2px;position:absolute;z-index:2}
.swal-icon--success__line--tip{width:25px;left:14px;top:46px;-webkit-transform:rotate(45deg);transform:rotate(45deg);-webkit-animation:animateSuccessTip .75s;animation:animateSuccessTip .75s}
.swal-icon--success__line--long{width:47px;right:8px;top:38px;-webkit-transform:rotate(-45deg);transform:rotate(-45deg);-webkit-animation:animateSuccessLong .75s;animation:animateSuccessLong .75s}@-webkit-keyframes rotatePlaceholder{0%{-webkit-transform:rotate(-45deg);transform:rotate(-45deg)}5%{-webkit-transform:rotate(-45deg);transform:rotate(-45deg)}12%{-webkit-transform:rotate(-405deg);transform:rotate(-405deg)}to{-webkit-transform:rotate(-405deg);transform:rotate(-405deg)}}@keyframes rotatePlaceholder{0%{-webkit-transform:rotate(-45deg);transform:rotate(-45deg)}5%{-webkit-transform:rotate(-45deg);transform:rotate(-45deg)}12%{-webkit-transform:rotate(-405deg);transform:rotate(-405deg)}to{-webkit-transform:rotate(-405deg);transform:rotate(-405deg)}}@-webkit-keyframes animateSuccessTip{0%{width:0;left:1px;top:19px}54%{width:0;left:1px;top:19px}70%{width:50px;left:-8px;top:37px}84%{width:17px;left:21px;top:48px}to{width:25px;left:14px;top:45px}}@keyframes animateSuccessTip{0%{width:0;left:1px;top:19px}54%{width:0;left:1px;top:19px}70%{width:50px;left:-8px;top:37px}84%{width:17px;left:21px;top:48px}to{width:25px;left:14px;top:45px}}@-webkit-keyframes animateSuccessLong{0%{width:0;right:46px;top:54px}65%{width:0;right:46px;top:54px}84%{width:55px;right:0;top:35px}to{width:47px;right:8px;top:38px}}@keyframes animateSuccessLong{0%{width:0;right:46px;top:54px}65%{width:0;right:46px;top:54px}84%{width:55px;right:0;top:35px}to{width:47px;right:8px;top:38px}}

@import "doc-scanner-interface";

#tds, #invest-smart  {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
}

//
// Scanner Interface
// --------------------------------------------------
#scanner {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  // height: 100vh;
  flex-grow: 1;

  background: $brand-primary;
}
#scanner-header {
  padding-top: 15px;
  padding-bottom: 15px;
  flex-shrink: 1;

  h1 {
    color: #fff;
    font-weight: 600;
    font-size: 22px;
  }

}
#scanner-body {
  overflow: auto;
  flex-grow: 1;
  flex: 6;

  h2.required-for {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    margin-top: 0;
    background: $brand-secondary;
    padding: 8px 12px;
    border-radius: 4px;
  }
  .fa-lightbulb-on,
  .fa-file-certificate {
    width: 35vw;
    max-width: 256px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 5vh;
    padding-bottom: 5vh;
    color: $brand-secondary;
  }
}
#scanner-footer {
  padding-top: 15px;
  padding-bottom: 15px;
  flex-shrink: 1;
  border-top: 2px solid rgba(255,255,255,0.25);

  .btn-success {
    border: 1px solid $brand-secondary;
    font-weight: 600;
    border-radius: 40px;
    text-transform: uppercase;
    padding: 10px 30px;
    //margin-left: 15px;

    &.btn-outline {
      background: none;
      color: $brand-secondary !important;
    }
    &.btn-solid {
      background: $brand-secondary;
      border: 1px solid $brand-secondary;
      color: #fff;
    }
  }
}

ul.files {
  list-style: none;
  padding-left: 0;
  margin-top: 8px;
  margin-left: -16px;
  margin-right: -16px;

  &>li {
    padding-right: 100px;
    position: relative;
    min-height: 34px;
    margin-bottom: 3px;
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 4px;
    padding-left: 16px;

    p {
      font-size: 15px;
      margin-bottom: 0;
      line-height: 28px;
      font-weight: 600;

      // truncate overflow
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .badge {
      margin-top: -10px;
      display: inline-block;
      border-radius: 3px;
      font-size: 10px;
      font-weight: 700 !important;
      text-transform: uppercase;
      text-shadow: 0 -1px rgba(0,0,0,0.25);
    }
    .badge-success { background: $brand-success; }
    .badge-danger { background: $brand-danger; }
    .badge-info { background: $brand-info; }

    .file-actions {
      position: absolute;
      top: 50%;
      right: 16px;
      margin-top: -16px;

      .btn { padding: 2px 10px;}
      .btn-view { color: $brand-info; }
      .btn-delete { color: $brand-danger; }
    }
  }
}

//
// Landing Page
// --------------------------------------------------
.panel-scanner-landing {
  margin: 32px;
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: center;

  .btn-success {
    border: 1px solid $brand-secondary;
    font-weight: 600;
    border-radius: 40px;
    text-transform: uppercase;
    padding: 10px 30px;
    margin-left: 15px;

    &.btn-outline {
      color: $brand-secondary;
    }
    &.btn-solid {
      background: $brand-secondary;
      border: 1px solid $brand-secondary;
      color: #fff;
    }
  }
}


//
// List Data
// --------------------------------------------------
.list-data {
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 6px;

  &>li {
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    padding: 0;
    background: #fff;
    color: #822A58;
    font-weight: 400;
    border-radius: 6px;
    margin-bottom: 12px;

    .text-muted { font-weight: 400; }
    &:first-child { border-top: 1px solid #ccc; }
    &:last-child { margin-bottom: 0; }
    // &:hover, &:focus { background: #f8f8f8; }

    &.data-heading {
      padding: 1rem;
      font-weight: 600;
      background: #F9FBFD;
      border: 1px solid #ccc;
      color: #95AAC9;
      font-size: 0.9375rem;
      border-radius: 6px 6px 0 0;
      // text-transform: uppercase; // disabled as lowercase text is easier to read
    }
    &>a {
      display: block;
      color: inherit;
      padding: 16px 16px;
      transition: 0.3s;

      &:hover, &:focus { text-decoration: none; }
    }
    .btn-info {
      border: 1px solid $brand-info;
      font-weight: 600;
      border-radius: 40px;
      text-transform: uppercase;
      padding: 6px 16px;
      background: $brand-info;
      border: 1px solid $brand-info;
      color: #fff;
      font-size: 14px;
    }
    p { margin-bottom: 0; }
  }
  &.no-links>li { padding: 16px 16px; }
  &.no-hover>li {
    &:hover, &:focus {
      background: #fff;

      &.data-heading { background: #F9FBFD; }
    }
  }
} // .list-data

//
// List Data Documents
.list-data-documents {
  &>li {
    .document-heading {
      background: #f0f0f0;
      margin-top: -16px;
      margin-left: -16px;
      margin-right: -16px;
      position: relative;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      padding: 12px 56px 12px 16px;

      h3 {
        margin-top: 0;
        font-weight: 600;
        font-size: 17px;
        margin-bottom: 0;
      }
      p { margin-bottom: 0; }
      .btn {
        position: absolute;
        top: 12px;
        right: 10px;
      }
    }
  }
}

//
// Scanner Images
.scanner-documents-outer {
  margin-left: -12px;
  margin-right: -12px;
}
.scanner-images {
  list-style: none;
  padding-left: 0;
  padding-top: 12px;
  position: relative;
  width: 100%;

  display: flex;
  flex-wrap: wrap;

  &>li {
    display: block;
    position: relative;
    width: 31%;
    margin: 1%;
    padding-bottom: 33.2%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    .btn {
      position: absolute;
      bottom: 5px;
      right: 5px;

      color: $brand-danger;
      background: rgba(255,255,255,0.75);
    }
    @media(min-width: 576px) {
      width: 23%;
      padding-bottom: 26.2%;
    }
    @media(min-width: 992px) {
      width: 18%;
      padding-bottom: 22.2%;

    }
  }
}


//
// Verification Modal
// --------------------------------------------------
.document-preview {
  background: #ccc;
  width: 100%;
  height: 50vh;
}
.modal-preview-document,
.modal-verify-document {
  .modal-content {
    border-radius: 8px !important;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    border: none;

    .modal-header {
      flex-shrink: 1;
      border-bottom: none;

      .modal-title {
        color: $brand-primary;
        font-weight: 400;
        font-size: 20px;
        margin-top: 10px;
      }
      .close {
        font-size: 28px;
        margin-top: 6px;
      }
    }
    .modal-body {
      flex-grow: 1; // take up space within modal content

      display: flex;
      flex-direction: column;

      .flex-grow-1 {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        .well {
          height: 100%;
          text-align: center;
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          justify-content: center;
        }
      }
      .flex-shrink-1 { flex-shrink: 1; }
      .form-control { height: 46px; }
    }
    .modal-footer {
      flex-shrink: 1;
      border-top: none;
      // padding-left: 0;
      // padding-right: 0;
      padding-bottom: 20px;

      .select2 { text-align: left; }
    }
  }
  @media (min-width: 768px) {
    &.modal-clean .modal-content .modal-body {
      padding: 20px;
    }
  }
}
