$brand-primary: #242342;

.panel-alert {
	border: 1px solid $brand-primary;
	border-radius: 0;
	background: rgba($brand-primary, 0.10);

	.panel-body { padding-left: 15px 20px; }
	i {
		font-size: 35px;
		line-height: 75px;
		color: $brand-primary;
	}
	h2 {
		font-weight: 400;
		font-size: 24px;
		margin: 8px 0 8px 0;
		color: $brand-primary;
	}
	p {
		margin-bottom: 0;
		color: $brand-primary;
		font-weight: 300;
		font-size: 15px;
		line-height: 22px;
	}
	.btn {
		border-radius: 0;
		border: 1px solid $brand-primary;
		background: none;
		color: $brand-primary;
		margin-top: 20px;
		margin-right: 10px;
		max-width: 200px;
		float: left;

		@media(min-width: 992px) { float: right; }
	}
}
