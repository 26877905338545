$brand-primary: #242342;
$brand-secondary: #18B499;
$brand-success: #5cb85c;
$brand-warning: #F7876B;
$brand-info: #5bc0de;
$brand-danger: #d9534f;

html, body {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  min-height: 100%;
}

#invest-smart {
  @import "buttons";

  /* --=== Login ===-- */
  #login-background {
    background-color: $brand-primary;
  }

  #login-container {
    .form-bordered .form-group.form-actions {
      background: #fafafa;
    }
    button.btn.btn-sm.btn-primary, a.btn.btn-sm.btn-primary,{
      float: left;
      background: $brand-secondary;
      border-color: $brand-secondary;
      border-radius: 40px;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 14px;
      padding: 8px 25px;
    }
    .login-powered-by {
      padding: 20px 20px 10px 20px;

      p {
        margin-bottom: 0;
        line-height: 32px;
        font-size: 13px;
        color: #858585;
      }
      img {
        float: right;
        max-height: 32px;
        max-width: 100%;
        margin-bottom: 0;
      }
    }
  }


  /* --=== Dashboard ===-- */
  #page-container,
  #sidebar,
  #sidebar-alt {
      background-color: $brand-primary;
  }
  #sidebar { padding-bottom: 90px; } // allow for powered by logo to sit below sidebar on small heights
  .sidebar-user-avatar {
    img { margin-left: 0 !important; }
  }
  .sidebar-nav a.active {
    border-left: 5px solid lighten($brand-primary, 10%);
      background: lighten($brand-primary, 10%);
  }
  #main-container .navbar.navbar-default {
    background: $brand-secondary;

    .nav>li {
      color: #fff;

      &>a { color: #fff; }
    }
  }
  .nav.navbar-nav-custom>li.open>a, .nav.navbar-nav-custom>li>a:focus, .nav.navbar-nav-custom>li>a:hover {
    background: darken($brand-secondary, 10%);
  }

  .content-header.content-header-media {
    background: url('../img/invest-smart/dashboard_header-investsmart.jpg');
    background-position: center center;
    background-size: cover;
    border-bottom: none;
  }
  footer.clearfix {
    position: relative;

    .powered-by {
      width: 200px;
      position: absolute;
      bottom: 12px;
      left: -200px;

      p {
        color: #fff;
        text-align: center;
        font-size: 13px;
        margin-bottom: 6px;
      }
      img {
        display: block;
        margin: auto;
      }
    }
  }

  @import "quick-search";
  @import "panel-alert";
  @import "rate-search";
  @import "incoming-investment";
  @import "modals";
  @import "../document-scanner";
}
