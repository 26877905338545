#scanner {
  height: 100%;
}
#document-scanner {
  position: relative;
  margin: auto;
  width:800px;
  max-width: 95%;

  // margin-top: -200px;
  // margin-bottom: 120px;



  // max-width: 90vw;
  // top:186px;
  // left: 50%;
  // margin-left: -400px;
  z-index: 1000;
  background: #fff;
  box-shadow: 0 0 10px rgba(0,0,0,.15);
  border-radius: 3px;

  .scanner-title {
    background: #eee;
    border-bottom: 1px solid #ccc;
    border-radius: 3px;

    &>.row {
      margin-left: 0;
      margin-right: 0;
    }
    img {
      max-width: 100%;

      &.primary {
        padding: 14px 0 14px 3px;
        width: 317px;
      }
    }

    h1 {
      margin-top: 0;
      padding-left: 15px;
      font-size: 22px;
      font-weight: 600;
    }

    .login-header-powered-by {
      padding: 10px 0 10px 10px;
      margin-bottom: 0;

      p {
        margin-bottom: 4px;
        font-size: 13px;
        text-align: right;
      }
      img {
        margin-left: auto;
        margin-right: 0;
        max-height: 32px;
        float: right;
      }
    }

  }


  @media(min-width: 768px) {
    // margin-top: -340px;

    .confirm-investment-title {
      .login-header-powered-by {
        text-align: right;
      }
    }
  }

  &>.block {
    padding-bottom: 20px;
    border:none;
  }
}
