$brand-secondary: #18B499;
$brand-warning: #F7876B;

#incoming-investment {
	position: relative;
	margin: auto;
	width:800px;
	max-width: 95%;
	margin-top: -200px;
	margin-bottom: 120px;
	// max-width: 90vw;
	// top:186px;
	// left: 50%;
	// margin-left: -400px;
	z-index: 1000;
	background: #fff;
	box-shadow: 0 0 10px rgba(0,0,0,.15);
	border-radius: 3px;

	.confirm-investment-title {
		background: #eee;
		border-bottom: 1px solid #ccc;
		border-radius: 3px;

		img {
			max-width: 100%;

			&.primary {
				padding: 14px 20px;
				width: 317px;
			}
		}

		.login-header-powered-by {
			padding: 10px 20px;
			margin-bottom: 0;

			p {
				margin-bottom: 4px;
				font-size: 13px;
			}
			img {
				max-height: 32px;
			}
		}

	}
	@media(min-width: 768px) {
		margin-top: -340px;

		.confirm-investment-title {
			.login-header-powered-by {
				text-align: right;
			}
		}
	}

	&>.block {
		padding-bottom: 20px;
		border:none;
	}

	.rates-notice {
		border-radius: 4px;
		background: #E8E8E8;
		padding: 10px 14px;

		p {
			font-size: 12px;
			margin-bottom: 0;
			line-height: 16px;

			&.strong {
				margin-bottom: 4px;
				font-weight: 600;
				font-size: 14px;
			}
		}
	}

	h2 {
		font-weight: 700;
		font-size: 16px;
		border-bottom: 1px dashed #eaedf1;
		padding-bottom: 12px;
		margin-top: 25px;
		margin-bottom: 20px;

		.btn.btn-sm {
			border-color: #E8E8E8;
			color: #777777;
			background: #fff;
			margin-top: -10px;
		}
		.edit-actions {
			.btn {
				padding: 5px 16px;
				margin-left: 6px;
				border-radius: 40px;
				border-color: $brand-secondary;
				color: $brand-secondary;
				text-transform: uppercase;
				font-weight: 600;
				font-size: 13px;
				background: #fff;
				margin-top: -10px;

				&.btn-solid {
					background: $brand-secondary;
					color: #fff;
				}
			}
		}
	}
	.form-group {
		margin-bottom: 10px;

		img {
			max-width: 100%;
			max-height: 25px;
			display: inline-block;
		}
		.form-control {
			height: 42px;
		}
		p.form-control-static {
			padding-top: 0;
			padding-bottom: 0;

			&.estimated-total {
				background: $brand-secondary;
				line-height: 44px;
				padding-left: 16px;
				color: #fff;
				font-weight: 700;
				font-size: 16px;
			}
		}
	}
	.alert.alert-info {
		background: #E9ECEF;
		border-color: darken(#E9ECEF, 10%);
		color: #777777;
	}

	/* --=== Select2 ===-- */
	.select2-container {
		width: 100% !important;

		.select2-selection--single {
			height: 42px;
			border-radius: 4px;

			.select2-selection__arrow {
				height: 32px;
				right: 6px;
			}
			.select2-selection__rendered {
				line-height: 40px;
				color: #007999;
			}
		}
		.select2-results__option {
			padding: 10px 15px;
		}
	}

	.actions .btn {
		border: 1px solid $brand-secondary;
		font-weight: 600;
		border-radius: 40px;
		text-transform: uppercase;
		padding: 10px 30px;
		margin-left: 15px;
		margin-top: 10px;

		&.btn-outline {
			color: $brand-secondary;
		}
		&.btn-solid {
			background: $brand-secondary;
			border: 1px solid $brand-secondary;
			color: #fff;
		}
	}

	@media (min-width: 768px) {
		.row-clear-sm-4>[class*="col-"] {
			&:nth-child(3n+1) { clear: both; }
		}
		.row-clear-sm-6>[class*="col-"] {
			&:nth-child(2n+1) { clear: both; }
		}
	}

	&.thanks {
		.block {
			padding: 30px;
		}
		h2 {
			font-weight: 300;
			font-size: 22px;
			margin-top: 40px;
		}
		p {
			font-size: 15px;
		}
		.actions {
			margin-bottom: 60px;

			.btn {
				margin-left: 0;
				width: 180px;
			}
		}
	}
}
