.btn-primary {
    background-color: $brand-primary;
    border-color: $brand-primary;
    color: #fff;
}
.btn-primary.active, .btn-primary.active:focus, .btn-primary.active:hover, .btn-primary.disabled, .btn-primary.disabled.active, .btn-primary.disabled:active, .btn-primary.disabled:focus, .btn-primary.disabled:hover, .btn-primary.focus, .btn-primary:active, .btn-primary:active:focus, .btn-primary:active:hover, .btn-primary:focus, .btn-primary[disabled].active, .btn-primary[disabled].active:focus, .btn-primary[disabled].focus, .btn-primary[disabled]:active, .btn-primary[disabled]:active:focus, .btn-primary[disabled]:focus, .btn-primary[disabled]:hover, .open .btn-primary.dropdown-toggle, .open .btn-primary.dropdown-toggle.focus, .open .btn-primary.dropdown-toggle:focus, .open .btn-primary.dropdown-toggle:hover, fieldset[disabled] .btn-primary.active, fieldset[disabled] .btn-primary.active:focus, fieldset[disabled] .btn-primary.focus, fieldset[disabled] .btn-primary:active, fieldset[disabled] .btn-primary:active:focus, fieldset[disabled] .btn-primary:focus, fieldset[disabled] .btn-primary:hover {
	background-color: darken($brand-primary, 5%);
    border-color: darken($brand-primary, 5%);
    color: #fff;
}


.btn-success {
    background-color: $brand-success;
    border-color: $brand-success;
    color: #fff;
}
.btn-success.active, .btn-success.active:focus, .btn-success.active:hover, .btn-success.disabled, .btn-success.disabled.active, .btn-success.disabled:active, .btn-success.disabled:focus, .btn-success.disabled:hover, .btn-success.focus, .btn-success:active, .btn-success:active:focus, .btn-success:active:hover, .btn-success:focus, .btn-success[disabled].active, .btn-success[disabled].active:focus, .btn-success[disabled].focus, .btn-success[disabled]:active, .btn-success[disabled]:active:focus, .btn-success[disabled]:focus, .btn-success[disabled]:hover, .open .btn-success.dropdown-toggle, .open .btn-success.dropdown-toggle.focus, .open .btn-success.dropdown-toggle:focus, .open .btn-success.dropdown-toggle:hover, fieldset[disabled] .btn-success.active, fieldset[disabled] .btn-success.active:focus, fieldset[disabled] .btn-success.focus, fieldset[disabled] .btn-success:active, fieldset[disabled] .btn-success:active:focus, fieldset[disabled] .btn-success:focus, fieldset[disabled] .btn-success:hover {
	background-color: darken($brand-success, 5%);
    border-color: darken($brand-success, 5%);
    color: #fff;
}


.btn-warning {
	background-color: $brand-warning;
    border-color: $brand-warning;
    color: #fff;
}
.btn-warning.active, .btn-warning.active:focus, .btn-warning.active:hover, .btn-warning.disabled, .btn-warning.disabled.active, .btn-warning.disabled:active, .btn-warning.disabled:focus, .btn-warning.disabled:hover, .btn-warning.focus, .btn-warning:active, .btn-warning:active:focus, .btn-warning:active:hover, .btn-warning:focus, .btn-warning[disabled].active, .btn-warning[disabled].active:focus, .btn-warning[disabled].focus, .btn-warning[disabled]:active, .btn-warning[disabled]:active:focus, .btn-warning[disabled]:focus, .btn-warning[disabled]:hover, .open .btn-warning.dropdown-toggle, .open .btn-warning.dropdown-toggle.focus, .open .btn-warning.dropdown-toggle:focus, .open .btn-warning.dropdown-toggle:hover, fieldset[disabled] .btn-warning.active, fieldset[disabled] .btn-warning.active:focus, fieldset[disabled] .btn-warning.focus, fieldset[disabled] .btn-warning:active, fieldset[disabled] .btn-warning:active:focus, fieldset[disabled] .btn-warning:focus, fieldset[disabled] .btn-warning:hover {
	background-color: darken($brand-warning, 5%);
    border-color: darken($brand-warning, 5%);
    color: #fff;
}


.btn-info {
    background-color: $brand-info;
    border-color: $brand-info;
    color: #fff;
}
.btn-info.active, .btn-info.active:focus, .btn-info.active:hover, .btn-info.disabled, .btn-info.disabled.active, .btn-info.disabled:active, .btn-info.disabled:focus, .btn-info.disabled:hover, .btn-info.focus, .btn-info:active, .btn-info:active:focus, .btn-info:active:hover, .btn-info:focus, .btn-info[disabled].active, .btn-info[disabled].active:focus, .btn-info[disabled].focus, .btn-info[disabled]:active, .btn-info[disabled]:active:focus, .btn-info[disabled]:focus, .btn-info[disabled]:hover, .open .btn-info.dropdown-toggle, .open .btn-info.dropdown-toggle.focus, .open .btn-info.dropdown-toggle:focus, .open .btn-info.dropdown-toggle:hover, fieldset[disabled] .btn-info.active, fieldset[disabled] .btn-info.active:focus, fieldset[disabled] .btn-info.focus, fieldset[disabled] .btn-info:active, fieldset[disabled] .btn-info:active:focus, fieldset[disabled] .btn-info:focus, fieldset[disabled] .btn-info:hover {
	background-color: darken($brand-info, 5%);
    border-color: darken($brand-info, 5%);
    color: #fff;
}


.btn-danger {
    background-color: $brand-danger;
    border-color: $brand-danger;
    color: #fff;
}
.btn-danger.active, .btn-danger.active:focus, .btn-danger.active:hover, .btn-danger.disabled, .btn-danger.disabled.active, .btn-danger.disabled:active, .btn-danger.disabled:focus, .btn-danger.disabled:hover, .btn-danger.focus, .btn-danger:active, .btn-danger:active:focus, .btn-danger:active:hover, .btn-danger:focus, .btn-danger[disabled].active, .btn-danger[disabled].active:focus, .btn-danger[disabled].focus, .btn-danger[disabled]:active, .btn-danger[disabled]:active:focus, .btn-danger[disabled]:focus, .btn-danger[disabled]:hover, .open .btn-danger.dropdown-toggle, .open .btn-danger.dropdown-toggle.focus, .open .btn-danger.dropdown-toggle:focus, .open .btn-danger.dropdown-toggle:hover, fieldset[disabled] .btn-danger.active, fieldset[disabled] .btn-danger.active:focus, fieldset[disabled] .btn-danger.focus, fieldset[disabled] .btn-danger:active, fieldset[disabled] .btn-danger:active:focus, fieldset[disabled] .btn-danger:focus, fieldset[disabled] .btn-danger:hover {
	background-color: darken($brand-danger, 5%);
    border-color: darken($brand-danger, 5%);
    color: #fff;
}