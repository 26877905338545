$brand-secondary: #18B499;

.quick-search {
	background: $brand-secondary;
	margin-left: -20px;
	margin-right: -20px;
	margin-top: -20px;
	padding: 30px;
	margin-bottom: 20px;

	h2 {
		color: #fff;
		margin: 0;
		font-weight: 400;
		font-size: 26px;
	}
	p {
		color: #fff;
		font-weight: 400;
		font-size: 15px;
	}
	.form-inline {
		float: right;

		.input-group {
			.input-group-addon {
				background: darken($brand-secondary, 10%);
				border-color: darken($brand-secondary, 10%);
				color: #fff;
				border-radius: 0;
				padding-left: 22px;
				padding-right: 22px;
			}
			.form-control {
				border-radius: 0;
				background: #F5F5F5;
				border: 1px solid #F5F5F5;
				height: 49px;
				padding-left: 20px;
				font-size: 16px;
				color: #18B499;

				font-weight: 700;

				&::placeholder {
					font-weight: 300;
				}
			}
		}
		.btn {
			background: darken($brand-secondary, 10%);
			border-color: darken($brand-secondary, 10%);
			border-radius: 0;
			font-size: 18px;
			text-transform: uppercase;
			font-weight: 700;
			padding: 11px 25px;
			letter-spacing: 1px;
		}
	}
	@media(min-width: 768px) {
		padding: 20px;

		p { margin-bottom: 0; }
		.form-inline {
			.input-group {
				.form-control {
					width: 220px;
				}
			}
			.btn {
				margin-left: 20px;
			}
		}
	}
	@media(min-width: 1200px) {
		.form-inline {
			.input-group {
				.form-control {
					width: 320px;
				}
			}
		}
	}
}
