.rate-search-form {
	background: $brand-secondary;
	padding: 18px 84px 18px 28px;
	position: relative;
	margin: -5px 0 20px 0;


	/* --=== Select2 ===-- */
	.select2-container {
		width: 100% !important;

		.select2-selection--single {
			height: 45px;
			border-radius: 0;
			background: #F5F5F5;
			border-color: #F5F5F5;

			.select2-selection__arrow {
				height: 32px;
				right: 6px;
			}
			.select2-selection__rendered {
				line-height: 43px;
				padding-left: 15px;
				font-weight: 600;
				font-size: 18px;
				color: #2a2a2a;
				letter-spacing: 1px;
			}
		}
		.select2-results__option {
			padding: 10px 15px;
			font-size: 14px;
			font-weight: 600;
		}
	}

	.control-label {
		color: #fff;
		font-size: 14px;
		font-weight: 700;
		margin-bottom: 2px;

		small {
			font-size: 12px;
			margin-left: 10px;
			font-weight: 400;
		}
	}
	.input-group {
		.input-group-addon {
			border-radius: 0;
			background: darken($brand-secondary, 10%);
			border-color: darken($brand-secondary, 10%);
			color: #fff;

			&.minor {
				background: #f5f5f5;
				border-color: #f5f5f5;
				color: #999;
			}
		}
		.form-control {
			background: #F5F5F5;
			border-color: #F5F5F5;
			height: 45px;
			border-radius: 0;
			font-weight: 600;
			padding-left: 15px;
			font-size: 18px;
			color: #2a2a2a;
			letter-spacing: 1px;
		}
	}
	.btn {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: 60px;
		border-radius: 0;
		background: darken($brand-secondary, 10%);
		color: #fff;
		font-size: 24px;
		transition: 0.2s;

		&:hover, &:focus {
			background: darken($brand-secondary, 15%);
		}
	}

	@media(min-width: 992px) {
		padding: 34px 94px 34px 34px;
	}
}